a {
  text-decoration: none;
  color: #ddd;
}

ul {
  padding-inline-start: 0;
  li {
    list-style: none;
  }
}
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-end: 0;
}

* {
  box-sizing: border-box;
  /* overflow-x: hidden; */
  margin: 0;
  padding: 0;
  color: #1f1f1f;
}

.message {
  width: 50px;
  height: 50px;
  position: fixed;
  left: 10px;
  bottom: 6%;
  z-index: 2000;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg.message {
    font-size: 40px;
    fill: #0e2973;
  }
}
